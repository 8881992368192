import { template as template_ff0342b0e2a44f669811a62a5c7f0943 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_ff0342b0e2a44f669811a62a5c7f0943(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
