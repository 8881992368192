import { template as template_be1484c6f417491ca1b741fb1cfed858 } from "@ember/template-compiler";
const FKText = template_be1484c6f417491ca1b741fb1cfed858(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
