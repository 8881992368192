import { template as template_0d23a5d2fe8348eeb439c94294059abe } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_0d23a5d2fe8348eeb439c94294059abe(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
