import { template as template_5216a893168c42839229ec154b50f969 } from "@ember/template-compiler";
const FKLabel = template_5216a893168c42839229ec154b50f969(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
